import React from "react";

const Mobile_Header = ({props}) => {

    return(
        <div id='mobile-header'>
            <img src={props.ownLogo} id="mobile-ownLogo" />
            {props.customerLogo !== '' ? 
                (
                    <>
                        <div className="vr divider mx-4"></div>
                        <img src={props.customerLogo} id="mobile-customerLogo" className="img-responsive plogo" alt="Customer Logo" />
                    </>
                )
                :
                null
            }
        </div>
    )

}

export default Mobile_Header;