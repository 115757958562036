import React from "react";
import Mobile_AQI_Display from "./Mobile_AQI_Display";
import imageData from '../../source_location.json';

function Mobile_Paging(data, jsonData, index, places){

    const item = data[index];
    const params = jsonData.param_config

    return(
        <>
            <div id="mobile-root">
                <div id="mobile-container">
                    <div id="mobile-top-container">
                        <div id="rectangle">
                            <div id='mobile-place-temp-humid-container'>
                                <div id='mobile-place-container'>
                                    {places.map((place, i) => (
                                        <div key={i} id='mobile-heading-container'>
                                            {(!jsonData.ospcb) && (i === index) && (
                                                <div>
                                                    {/* <img src='/img/Indoor_mobile.png' alt='Indoor Mobile' style={{zIndex: '-1'}} /> */}
                                                    <p>{place.place}</p>
                                                </div>
                                            )}
                                            {(jsonData.ospcb) && (i === index) && (
                                                <div>
                                                    {/* <img src='/img/Indoor_mobile.png' alt='Indoor Mobile' /> */}
                                                    <p>{place.place}</p>
                                                        </div>
                                            )}
                                            {(jsonData.ospcb) && (i !== index) && (jsonData.comparisonMode !== 3) && (
                                                <div>
                                                    {/* <img src='/img/Outdoor_mobile.png' alt='Indoor Mobile' /> */}
                                                    <p>{jsonData.cpcb_params['filters[station]']}</p>
                                                </div>
                                            )}
                                            {(jsonData.ospcb) && (i !== index) && (jsonData.comparisonMode === 3) && (
                                                <div>
                                                    {/* <img src='/img/Indoor_mobile.png' alt='Indoor Mobile' style={{zIndex: '-1'}} /> */}
                                                    <p>{jsonData.outdoorLocation}</p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div id='mobile-temp-container'>
                                    <img src="/img/mobile-temperature.svg" alt='Temperature' id='mobile-temp-icon'></img>
                                    {(data[index].params !== undefined) ? 
                                        (data[index].params.temp !== undefined ? 
                                            (<div id='mobile-temp'>{Math.round(data[index].params.temp)} <span id='Celcius' style={{fontSize: '11px'}}>&deg;C</span> </div>) :
                                            (data[index].params.temperature !== undefined ? 
                                                (<div id='mobile-temp'>{Math.round(data[index].params.temperature)} <span id='Celcius' style={{fontSize: '11px'}}>&deg;C</span></div>) :
                                                (<div id='mobile-temp'>NA</div>)
                                            )
                                        )
                                        :
                                        (<div id='mobile-temp'>NA</div>)
                                    }
                                </div>
                                <div id='mobile-humid-container'>
                                    <img src="/img/mobile-humidity.svg" alt='Humidity' id='mobile-humid-icon'></img>
                                    {(data[index].params !== undefined) ? 
                                        (data[index].params.humid !== undefined ? 
                                            (<div id='mobile-humid'>{Math.round(data[index].params.humid)} %</div>) :
                                            (data[index].params.humidity !== undefined ? 
                                                (<div id='mobile-humid'>{Math.round(data[index].params.humidity)} %</div>) :
                                                (<div id='mobile-humid'>NA</div>)
                                            )
                                        )
                                        :
                                        (<div id='mobile-humid'>NA</div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div id='mobile-aqi-container'><Mobile_AQI_Display data={item} color={item.color} aqi={jsonData.param}/></div>
                        {((item.aqi === undefined || item.aqi === 0 || isNaN(item.aqi)) && <img src='/img/none-aqi-image.png ' alt='No AQI Available' id='mobile-aqi-condition-image'/>)}
                        {(item.aqi > 0 && item.aqi <= 50 && <img src='/img/good-aqi-image.png' alt='Good' id='mobile-aqi-condition-image'/>)}
                        {(item.aqi > 50 && item.aqi <= 100 && <img src='/img/satisfactory-aqi-image.png' alt='Satisfactory' id='mobile-aqi-condition-image'/>)}
                        {(item.aqi > 100 && item.aqi <= 200 && <img src='/img/moderate-aqi-image.png' alt='Moderate' id='mobile-aqi-condition-image'/>)}
                        {(item.aqi > 200 && item.aqi <= 300 && <img src='/img/poor-aqi-image.png' alt='Poor' id='mobile-aqi-condition-image'/>)}
                        {(item.aqi > 300 && item.aqi <= 400 && <img src='/img/verypoor-aqi-image.png' alt='Very Poor' id='mobile-aqi-condition-image'/>)}
                        {(item.aqi > 400 && item.aqi <= 500 && <img src='/img/severe-aqi-image.png' alt='Severe' id='mobile-aqi-condition-image'/>)}
                        {(item.aqi > 500 && <img src='/img/severe-aqi-image.png' alt='Severe' id='mobile-aqi-condition-image'/>)}
                    </div>
                    <div id="mobile-bottom-container">
                        {params && jsonData.adv && (
                            <div id='mobile-minor-details'>
                                {jsonData.param_config.map((type, i) => {
                                    const value = item.params[type];
                                    var formattedValue;
                                    if (value === undefined || value === null || isNaN(Number(value))) {
                                        formattedValue = "NA";
                                    }
                                    else{
                                        formattedValue = Number(value).toFixed(2);
                                    }
                                    if(type !== 'temp' && type !== 'humid' && type !== 'temperature' && type !== 'humidity' && !(type === 'pm2.5' && jsonData.param !== undefined)){
                                        return(
                                            <div id='mobile-data-container' key={i} >
                                                <p id="mobile-type">{type.toUpperCase()}</p>
                                                <p id="mobile-value" style={{color: `${item.color[type.toUpperCase()]}`}}>{formattedValue}</p>
                                                <p id="mobile-unit">{imageData.units[type]}</p>
                                            </div>
                                        )
                                    }
                                }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )

}

export default Mobile_Paging;