import React from 'react';
import AQI from './AQI';
import imageData from '../source_location.json';
import AQI_icon from './AQI_icon';

function DeviceRenderer (data, index, places, jsonData){

    const params = jsonData.param_config;
    const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true 
    };  
    const flag = jsonData.paging;

    return(
        <>
            <div className='paging-parent'>
                {places
                    .filter(place => place.thing_id === data[index].thing_id)
                    .map((place, i) => (
                        <div key={i} className='paging-heading-container'>
                            <h2 className='paging-heading'>{place.place}</h2>
                        </div>
                ))}
                <div className='paging-major'>
                    {jsonData.adv ? 
                        (
                            <div id='paging-temp-humid-display'>
                                <div id='paging-temp-display'>
                                    <img src="/img/temp.png" alt='Temperature' id='paging-temp_icon'></img>
                                        {(data[index].params !== undefined) ? 
                                            (data[index].params.temp !== undefined ? 
                                                (<div id='paging-temp'>{Math.round(data[index].params.temp)} &deg;C</div>) :
                                                (data[index].params.temperature !== undefined ? 
                                                    (<div id='paging-temp'>{Math.round(data[index].params.temperature)} &deg;C</div>) :
                                                    (<div id='paging-temp'>NA</div>)
                                                )
                                            )
                                            :
                                            (<div id='paging-temp'>NA</div>)
                                        }
                                </div>
                                <div id='paging-humid-display'>
                                    <img src="/img/humid.png" alt='Humidity' id='paging-humid_icon'></img>
                                        {(data[index].params !== undefined) ? 
                                            (data[index].params.humid !== undefined ? 
                                                (<div id='paging-humidity'>{Math.round(data[index].params.humid)} %</div>) :
                                                (data[index].params.humidity !== undefined ? 
                                                    (<div id='paging-humidity'>{Math.round(data[index].params.humidity)} %</div>) :
                                                    (<div id='paging-humidity'>NA</div>)
                                                )
                                            )
                                            :
                                            (<div id='paging-humidity'>NA</div>)
                                        }
                                </div>
                            </div>
                        ) 
                        :null
                    }
                    <div id="paging-aqi-res-display">
                        <div className="AQI" id="paging-aqi-display">
                            <AQI_icon val={data[index]} jsonData={jsonData} />
                        </div>
                    <AQI data = {data[index]} flag = {flag}/>
                    </div>
                </div>
                {params && jsonData.adv &&(
                    <div className='paging-minor'>
                    {Object.entries(data[index].params).map(([key, value], i) => {
                        if (key !== 'temp' && key !== 'humid' && key !== 'temperature' && key !== 'humidity'  && !(key === 'pm2.5' && jsonData.param !== undefined)) {
                            return (
                                <div className="pm25_container" id="paging-pm25" key={i} style={{ borderBottom: `4px solid ${data[index].color[key.toUpperCase()]}` }}>
                                    <div className="left-hand" id="paging-left">
                                        <img src={imageData.images[key]} id="paging-pm25-img" />
                                        <p className="paging-type">{key.toUpperCase()}</p>
                                    </div>
                                    <div className="right-hand" id="paging-right">
                                        <p className="paging-val" style={{ color: data[index].color[key.toUpperCase()] }}>{Math.round(value * 100)/100}</p>
                                        <p className="paging-unit">{imageData.units[key]}</p>
                                    </div>
                                </div>
                            );
                        }
                    return null;
                    })}
                    </div>
                )}
                {/* <div id='paging-time'>Last Updated : {new Date(data[0].time * 1000).toLocaleString('en-US', options)}</div> */}
                <div id='paging-time'>Last Updated : {data[0].time}</div>
            </div>
        </>
    )
};

export default DeviceRenderer;