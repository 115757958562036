import React from "react";

const Footer = ({props}) => {

    return(

        <div id='footer'>
            <div id='partner-container'>
                {props.partnerIcon !== '' ?
                    (
                        <img src={props.partnerIcon} id='partnerIcon' alt='Partner' />
                    )
                    : null
                }
                {props.partnerText !== '' ?
                    (
                        <h4 id='partnerText'>{props.partnerText}</h4>
                    )
                    :null
                }
            </div>
            <div id='aqi_summary'>
                {props.pmessage !== '' ?
                    (
                        <h5 id='pmessage'>{props.pmessage}</h5>
                    ):null
                }
                <img src='/img/aqi_scale.png' id='aqi_scale_img' alt='AQI scale' />
            </div>
            <div id='own-container'>
                {props.colabIcon !== '' ? 
                    (
                        <img id='colabIcon' src={props.colabIcon} alt='Colab' />
                    ):null
                }
                {props.colabText !== '' ? 
                    (
                        <h5 id='colabText'>{props.colabText}</h5>
                    ):null
                }
            </div>
        </div>

    )

}

export default Footer;