import React from "react";

const AQIIcon = ({val, jsonData}) => {

    const item = {val}.val;
    var temp, ans;
    if(item.aqi === undefined){
        ans = "NA"
    }
    else if(item.aqi && isNaN(item.aqi.aqi)){
        ans = "NA"
    }
    else if(item.aqi['pm2.5']){
        ans = item.aqi["pm2.5"].avg;
    }
    else{
        ans = item.aqi;
    }
    if(jsonData.param !== undefined){
        if(typeof(item.color['pm2.5']) !== 'undefined'){
            temp = item.color['pm2.5'];
        }
        else{
            temp = item.color['aqi'];
        }
    }
    else{
        temp = item.color['aqi']
    }

    let roundedAQI;
    if(isNaN(item.aqi)){
        roundedAQI = 'NA';
    }
    else{
        roundedAQI = Math.round(item.aqi);
    }
    
    if (jsonData.ospcb) {
        
        return(

            <>
                <div id="holder-original"  style={{backgroundColor: temp}}>
                    {jsonData.paid === false ? 
                    (<div id="test-original">
                        {(ans === undefined || ans === 0 || isNaN(ans)) && <img src="/img/aqi0.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                        {ans > 0 && ans <= 50 && <img src="/img/aqi1.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                        {ans > 50 && ans <= 100 && <img src="/img/aqi2.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                        {ans > 100 && ans <= 200 && <img src="/img/aqi3.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                        {ans > 200 && ans <= 300 && <img src="/img/aqi4.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                        {ans > 300 && ans <= 400 && <img src="/img/aqi5.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                        {ans > 400 && ans <= 500 && <img src="/img/aqi6.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                        {ans > 500 && <img src="/img/aqi6.png" alt='AQI_image' id='new-aqi-image-original'></img>}
                    </div>):
                    null
                    }   
                    <div id='alt-x'>
                        {!jsonData.param ?
                            <section>AQI</section>:
                            <section>PM2.5</section>
                        }
                        {item.aqi ? 
                            item.aqi.hasOwnProperty('pm2.5') ? 
                                (isNaN(ans) ? (<p>NA</p>):<p>{Math.round(ans)}</p>):
                                item.aqi.hasOwnProperty('aqi') ?
                                <p>{roundedAQI}</p>:
                                <p>{isNaN(roundedAQI) ? 'NA' : roundedAQI.toString()}</p>
                                :
                            <p>NA</p>
                        }
                    </div>
                </div>
            </>

        )

    }
    else{

        return(

            <div id="holder" style={{backgroundColor: item.color.aqi}}>
                <div id="test">
                    {(ans === undefined || ans === 0 || isNaN(ans)) && <img src="/img/aqi0.png" alt='AQI_image' id='new-aqi-image'></img>}
                    {ans > 0 && ans <= 50 && <img src="/img/aqi1.png" alt='AQI_image' id='new-aqi-image'></img>}
                    {ans > 50 && ans <= 100 && <img src="/img/aqi2.png" alt='AQI_image' id='new-aqi-image'></img>}
                    {ans > 100 && ans <= 200 && <img src="/img/aqi3.png" alt='AQI_image' id='new-aqi-image'></img>}
                    {ans > 200 && ans <= 300 && <img src="/img/aqi4.png" alt='AQI_image' id='new-aqi-image'></img>}
                    {ans > 300 && ans <= 400 && <img src="/img/aqi5.png" alt='AQI_image' id='new-aqi-image'></img>}
                    {ans > 400 && ans <= 500 && <img src="/img/aqi6.png" alt='AQI_image' id='new-aqi-image'></img>}
                    {ans > 500 && <img src="/img/aqi6.png" alt='AQI_image' id='new-aqi-image'></img>}
                </div>
                <div id='x'>
                    {!jsonData.param ?
                        <section>AQI</section>:
                        <section>PM2.5</section>
                    }
                    {item.aqi ? 
                        item.aqi.hasOwnProperty('pm2.5') ?
                            <p>{Math.round(item.aqi['pm2.5'].avg)}</p> :
                            item.aqi.hasOwnProperty('aqi') ?
                            <p>{roundedAQI}</p>:
                            <p>{isNaN(roundedAQI) ? 'NA' : roundedAQI.toString()}</p>
                            :
                        <p>NA</p>
                    }
                </div>
            </div>

        )

    }
    

}

export default AQIIcon;