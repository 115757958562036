import React from "react";

const Mobile_AQI_Display = ({data, color, aqi}) => {

    var item, col;
    if(data.aqi === undefined || data.aqi === 'NA'){

        item = "NA";
        col = '#B9B9B9';

    }
    else if(typeof data.aqi === 'object' && data.aqi['pm2.5'] && data.aqi['pm2.5'].avg !== undefined){

        item = data.aqi['pm2.5'].avg;
        col = color;
        
    }
    else{

        item = data.aqi;
        col = color.aqi;
        if(color.aqi === undefined){
            col = color['pm2.5'];
        }
        
    }

    return(

        <>
            <div id="aqi-container" style={{backgroundColor: `${col}`}}>
                <div>
                    {aqi === undefined ? 
                    (   <p>AQI</p> )
                    :(  <p>{aqi}</p> )
                    }
                    <div id="data">{Math.round(item)}</div>
                </div>
            </div>
            {(item === 0 || item === undefined) && 
                <p></p>
            }
            {item > 0 && item <= 50 && 
                <p>Good</p>
            }
            {item > 50 && item <= 100 && 
                <p>Satisfactory</p>
            }
            {item > 100 && item <= 200 &&
                <p>Moderate</p>
            }
            {item > 200 && item <= 300 &&
                <p>Poor</p>
            }
            {item > 300 && item <= 400 &&
                <p>Very Poor</p>
            }
            {item > 400 && item <= 500 &&
                <p>Severe</p>
            }
            {item > 500 &&
                <p>Severe</p>
            }
        </>

    )

}

export default Mobile_AQI_Display;